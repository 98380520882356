import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import Breadcrumbs from "../components/molecules/breadcrumbs";

function DesignBreadcrumbs() {
  return (
    <div className="design__content">
      <SEO title="Design System - Breadcrumbs" />
      <h2>Breadcrumbs</h2>

      <div className="design__stories">
        <div className="design__story-section">
          <h5>Breadcrumbs</h5>
          <DesignStory
            title="PDP Breadcrumbs"
            type="component"
            component={Breadcrumbs}
            props={{
              content: [
                {
                  id: "shop-all-bc",
                  title: "Shop All",
                  url: "#",
                },
                {
                  id: "euc-bc",
                  title: "Eucalyptus",
                  url: "#",
                },
                {
                  id: "breeze-bc",
                  title: "The Buffy Breeze",
                },
              ],
              grid: false,
            }}
          />
          <DesignStory
            title="Cart Breadcrumbs"
            type="component"
            component={Breadcrumbs}
            props={{
              content: [
                {
                  id: "continue-bc",
                  title: "Continue Shopping",
                  url: "#",
                  back: true,
                },
              ],
              grid: false,
            }}
          />
        </div>

        <div className="design__story-section">
          <DesignProps component={Breadcrumbs} title="Breadcrumbs Properties" />
        </div>
      </div>
    </div>
  );
}

export default DesignBreadcrumbs;
